import './style.scss'

import $ from 'jquery'
import slick from 'slick-carousel'
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js"
import '../node_modules/aos/dist/aos.css'; // You can also use <link> for styles
import AOS from 'aos';

// import Router from './util/Router';
// import common from './routes/common';
// import home from './routes/home';

// /**
//  * Populate Router instance with DOM routes
//  * @type {Router} routes - An instance of our router
//  */
// const routes = new Router({
//   /** All pages */
//   common,
//   /** Home page */
//   home,
//   /** About Us page, note the change from about-us to aboutUs. */
// });
//

// VIDEO AUTOPLAY
document.getElementById('video-middle').play()


// ONSCROLL ADD CLASS TO NAVBAR
var scrollPosition = window.scrollY
var navBar = document.getElementsByClassName('navbar')
navBar = navBar[0]
if (scrollPosition >= 30) {
  navBar.classList.add('scrolled')
} else {
  navBar.classList.remove('scrolled')
}
window.addEventListener('scroll', function() {
  scrollPosition = window.scrollY
  if (scrollPosition >= 30) {
    navBar.classList.add('scrolled')
  } else {
    navBar.classList.remove('scrolled')
  }
})

// /** Load Events */
$(document).ready(function(){

  var ap = true

  // LOAD PARTIAL SLIDER IMAGES
  $('.slider-img').slick({
    autoplay: ap,
    autoplaySpeed: 7000,
    speed: 800,
    focusOnSelect: true,
    infinite: true,
    arrows: true,
    variableWidth: true,
    // onInit: function () {
    //   jQuery(window).resize()
    //   console.log('slickcaroseal locded')
    // },
    centerMode: true,
    prevArrow: $('body').find('.gallery-block .slick-left'),
    nextArrow: $('body').find('.gallery-block .slick-right')
  })


  // MANAGE TOGGLE MENU BUTTON
  // $(".navbar-toggler").on("click", function(){
  //   $(this).toggleClass("expanded")
  // })
  $('.menu-toggler').on('click', function () {
    $(this).toggleClass('open')
    $('nav').toggleClass('open')
  })
  $('nav .nav-link').on('click', function () {
    $('.menu-toggler').removeClass('open')
    $('nav').removeClass('open')
  })


  //MANAGE ANCHOR INDEX NAV 2 BLOCK
  $('a[data-goto]').on('click', function (e) {
    e.preventDefault()
    var goto = $(this).data("goto")
    console.log(goto)
    $('html, body').animate({
        scrollTop: ($("#" + goto).offset().top) - 150
    }, 400)
  })


  // OPEN POPUP IN ELEMENT VIDEO IN VIEW
  // var already_displayed = false
  // $(window).scroll(function() {
  //   var top_of_element = $("#video").offset().top
  //   var bottom_of_element = $("#video").offset().top + $("#video").outerHeight()
  //   var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight()
  //   var top_of_screen = $(window).scrollTop()
  //   if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
  //     if (!already_displayed) {
  //       Fancybox.show([{ src: "#kickstarter-pop", type: "inline" }])
  //       already_displayed = true
  //     }
  //   } else {
  //     // not in view
  //     // console.log("false")
  //   }
  // })


  //GOTOP BUTTON
  $("#gotop").click(function () {
   $("html, body").animate({scrollTop: 0}, 1000);
 })


  AOS.init({
    delay: 100
  })
  window.addEventListener('load', AOS.refresh)

})
